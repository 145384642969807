<template>
  <div class="apassword">
    <van-nav-bar
      :title="$t('CHANGEPASSWORD')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <van-cell-group>
      <van-field type="password" v-model="value" :label="$t('PASSWORD')" :placeholder="$t('THENEWPASSWORD')" />
      <van-field type="password" v-model="checkpwd" :label="$t('CHECKPASSWORDRe')" :placeholder="$t('CHECKPASSWORDRe')" />
    </van-cell-group>
    <van-button color="#759FED" @click="sub" class="password" type="primary" block>{{$t('CHANGEPASSWORD')}}</van-button>
  </div>
</template>

<script>
import { changePassword } from '@/api/my'
import { Toast } from 'vant'
export default {
  name: 'apassword',
  data () {
    return {
      value: '',
      checkpwd: ''
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      if (this.value !== this.checkpwd) {
        Toast(this.$t('TWICE'))
        return false
      }
      try {
        const { data } = await changePassword({
          password: this.value
        })
        if (data.code === 200) {
          Toast(this.$t('MODIFYTHESUCCESS'))
        } else {
          Toast(data.message)
        }
        // console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.apassword >>> .van-nav-bar {
  background-color: #759FED;
  color: #fff;
}
.apassword >>> .van-nav-bar__title {
  color: #fff;
}
.password {
  width: 80%;
  margin: 20px auto;
  background-color: #00BFFF;
}
</style>
